import React, { useContext, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import EmailContext from "../../context/EmailContext"
import axios from "axios"
import SimpleModal from "./SimpleModal"
import { AnimatePresence } from "framer-motion"

const FormInputs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm()
  const [email] = useContext(EmailContext)
  const [succes, setSucces] = useState(true)
  const [error, setError] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const close = () => setModalOpen(false)
  const open = () => setModalOpen(true)

  useEffect(() => {
    setValue("email", email.email)
  }, [])

  const onSubmit = data => {
    setSucces(false)
    setError(false)
    setIsPending(true)
    axios
      .post(`${process.env.GATSBY_API_URL}/contact-us`, data, {
        "Content-Type": "application/json",
      })
      .then(() => {
        setSucces(true)
        setIsPending(false)
        open()
      })
      .catch(() => {
        setError(true)
        setIsPending(false)
        open()
      })
      .finally(() => {
        reset()
      })
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col bg-white rounded-2xl"
        autoComplete="off"
      >
        <label className="mb-4">
          <p className="mb-2 text-secondary">Name</p>
          <input
            type="text"
            name="name"
            placeholder="ex: Livia Siphron"
            {...register("name", { required: true })}
            className="py-3 px-6 border placeholder-opacity-60  placeholder-[#1D263A] rounded-xl  focus:outline-none block w-full"
          />
          {errors.name && <span>This field is required</span>}
        </label>
        <label className="mb-4">
          <p className="mb-2 text-secondary">Email</p>
          <input
            type="text"
            name="email"
            placeholder="Your Email"
            {...register("email", {
              required: true,
              pattern: /^\S+@\S+$/i,
            })}
            className="py-3 px-6 border placeholder-opacity-60  placeholder-[#1D263A] rounded-xl  focus:outline-none block w-full"
          />
          {errors.email && <span>This field is required</span>}
        </label>
        <label className="mb-4">
          <p className="mb-2  text-secondary">Phone Number</p>
          <input
            type="text"
            placeholder="Your phone number"
            {...register("phone")}
            className="py-3 px-6 border placeholder-opacity-60  placeholder-[#1D263A] rounded-xl  focus:outline-none block w-full"
          />
        </label>
        <label className="mb-6">
          <p className="mb-2 text-secondary">Subject</p>
          <input
            type="text"
            name="subject"
            placeholder="i.e. I want to know about your service"
            {...register("subject")}
            className="py-3 px-6 border placeholder-opacity-60  placeholder-[#1D263A] rounded-xl  focus:outline-none block w-full"
          />
        </label>
        <label className="w-full mb-6">
          <p className="mb-2 text-secondary">Message</p>
          <textarea
            placeholder="Write your messaage or inquiry"
            name="message"
            {...register("message", { required: false })}
            className="resize-none  rounded-xl h-48 py-5 px-6 border placeholder-opacity-60  placeholder-[#1D263A]   focus:outline-none w-full block"
          />
        </label>
        <button
          type="submit"
          className="bg-secondary py-4 rounded-full text-white flex items-center justify-center"
        >
          {isPending ? (
            <svg
              role="status"
              class="w-6 h-6  text-gray-200 animate-spin  fill-white"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          ) : (
            <span> Submit</span>
          )}
        </button>
      </form>
      <AnimatePresence
        // Disable any initial animations on children that
        // are present when the component is first rendered
        initial={false}
        // Only render one component at a time.
        // The exiting component will finish its exit
        // animation before entering component is rendered
        exitBeforeEnter={true}
        // Fires when all exiting nodes have completed animating out
        onExitComplete={() => null}
      >
        {modalOpen && (
          <SimpleModal handleClose={close}>
            {succes && (
              <p className="text-black my-2  font-medium ">
                Thank you for reaching out to us, a member of our team will be
                contacting you soon.
              </p>
            )}
            {error && (
              <p className="text-black my-2  font-medium ">
                Sorry something wrong happened.
              </p>
            )}
          </SimpleModal>
        )}
      </AnimatePresence>
    </>
  )
}
export default FormInputs
