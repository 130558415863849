import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import FormInputs from "../components/DemoForm"
import Seo from "../components/seo"

const DemoPage = () => {
  return (
    <>
      <Seo title="Request a Demo" />
      <div className=" min-h-full grid lg:grid-cols-2">
        <div className="relative hidden lg:block w-full h-full">
          <StaticImage
            src=".././images/demo-bg.png"
            placeholder="blurred"
            alt="demo"
            className="absolute inset-0 h-full w-full object-cover"
          />
        </div>
        <div className="flex flex-col justify-center px-4 py-12 sm:px-20">
          <div className=" mx-auto z-10 w-full">
            <h2 className="mb-6 text-secondary  text-xl lg:text-3xl ">
              Request a Demo
            </h2>
            <h3 className="md:text-xl text-secondary mb-8">Find out more</h3>
            <FormInputs />
          </div>
        </div>
      </div>
    </>
  )
}
export default DemoPage
