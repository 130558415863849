import React from "react"
import { motion } from "framer-motion"
import Backdrop from "../Backdrop"

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
}

const SimpleModal = ({ handleClose, children }) => {
  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={e => e.stopPropagation()}
        className="modal     bg-white p-6 lg:p-8  relative "
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="h-full flex-1 flex justify-between">
          <div className="w-full">{children}</div>
        </div>
        <button
          onClick={handleClose}
          className="flex items-center text-[#FF0000] md:text-2xl absolute right-6 top-6 lg:right-8 lg:top-8"
        >
          <span>{Close}</span>
        </button>
      </motion.div>
    </Backdrop>
  )
}

export default SimpleModal

const Close = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    className="w-8 h-8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 3.5L10.9516 10.9516"
      stroke="#FF0000"
      strokeWidth="1.2"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 10.9517L10.9516 3.50005"
      stroke="#FF0000"
      strokeWidth="1.2"
      stroke-linecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
